import { PageContext } from 'vike/types';

function onBeforeRoute(pageContext: PageContext) {
  function extractLocale(urlPathname: string) {
    const hostname = pageContext.hostname;
    // Get .co or .es from hostname

    if (!hostname) {
      return {
        locale: 'en',
        urlWithoutLocale: urlPathname,
      };
    }
    const tld = hostname.split('.').pop();
    const locale = tld === 'es' ? 'es' : 'en';
    return {
      locale,
      urlWithoutLocale: urlPathname,
    };
  }

  const { urlWithoutLocale, locale } = extractLocale(pageContext.urlPathname);

  return {
    pageContext: {
      // Make `locale` available as `pageContext.locale`
      locale,
      // Vike's router will use pageContext.urlLogical instead of pageContext.urlOriginal
      urlLogical: urlWithoutLocale,
    },
  };
}

export { onBeforeRoute };
